'use client';

import { NextJsErrorPageProps } from '@/types';
import { Banner } from '@knapsack/toby';

export default function ErrorPage({ error, reset }: NextJsErrorPageProps) {
  return (
    <Banner
      type="error"
      title={error instanceof Error ? error.message : JSON.stringify(error)}
    />
  );
}
